.Text{
    font-size:13px;
    text-align:center;
    line-height: 12px;
}
.text2{
    font-size:13px;
    text-align:center;
    line-height: 10px;
    /* font-weight: 600; */
}
.textLeft{
    font-size:13px;
    text-align: left;
    line-height: 10px;
}
.textLeft2{
    font-size:10px;
    text-align: left;
    line-height: 8px;
}
.rightText{
  font-size: 10px;
  text-align: right;
  line-height: 8px;
}
