/* Hide the element by default */
/* .print-only {
    display: none;
  } */
  
  /* Make it visible when printing */
  /* @media print {
    .print-only {
      display: block;
    }
  } */
  .footer {
    display: flex;
    gap: 45px; /* Adjust the gap value as needed */
    justify-content: flex-end; /* Adjust alignment as needed */
    align-items: center; /* Adjust alignment as needed */
    font-size: '10px';
    font-weight:'800';
    padding-right: 15px;
  }
  .no-results-found .p-datatable-emptymessage {
    display: none !important;
  }
  