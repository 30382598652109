* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.fileUpload {
  background-color: #6366f1;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  width: 200px;
  
}
